import { NgModule } from "@angular/core";
import { PreloadAllModules, Routes, RouterModule } from "@angular/router";

import { HomeComponent } from "./home";
import { LoginComponent } from "./login";
import { RegisterComponent } from "./register";
import { ForgotPasswordComponent } from "./forgotpassword";
import { EntermarksComponent } from "./entermarks";
import { AuthGuard } from "./_guards";
import { ViewAssignmentComponent } from "./Syllabus/viewassignment/viewassignment.component";
import { AssignmentsComponent } from "./Syllabus/assignments/assignments.component";
import { SyllabusComponent } from "./Syllabus/syllabus/syllabus.component";
import { LecturesComponent } from "./Syllabus/lectures/lectures.component";
import { TeachforsubComponent } from './teachforsub/teachforsub.component';
const appRoutes: Routes = [
  { path: "", component: HomeComponent, canActivate: [AuthGuard] },
  { path: "login", component: LoginComponent },
  { path: "register", component: RegisterComponent },
  { path: "forgotpassword", component: ForgotPasswordComponent },
  {
    path: "entermarks",
    component: EntermarksComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "viewassignment",
    component: ViewAssignmentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "assignments",
    component: AssignmentsComponent,
    canActivate: [AuthGuard],
  },
  { path: "modules", component: SyllabusComponent, canActivate: [AuthGuard] },
  { path: "lectures", component: LecturesComponent, canActivate: [AuthGuard] },
  { path: "teacherforsubjects", component: TeachforsubComponent, canActivate: [AuthGuard] },
  {
    path: "",
    redirectTo: "folder/Inbox",
    pathMatch: "full",
  },
  {
    path: "folder/:id",
    loadChildren: () =>
      import("./folder/folder.module").then((m) => m.FolderPageModule),
  },

  // otherwise redirect to home
  { path: "**", redirectTo: "" },
];

const routes: Routes = [
  {
    path: "",
    redirectTo: "folder/Inbox",
    pathMatch: "full",
  },
  {
    path: "folder/:id",
    loadChildren: () =>
      import("./folder/folder.module").then((m) => m.FolderPageModule),
  },
  {
    path: "lecture-modal",
    loadChildren: () =>
      import("./modals/lecture-modal/lecture-modal.module").then(
        (m) => m.LectureModalPageModule
      ),
  },
];

// @NgModule({
//   imports: [
//     RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
//   ],
//   exports: [RouterModule]
// })
// export class AppRoutingModule {}

export const routing = RouterModule.forRoot(appRoutes, { useHash: true });
