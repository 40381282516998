import { Component, OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { Lecture } from "@app/_models";
import { GlobalProvider } from "../../../providers/global/global";
@Component({
  selector: "app-lecture-modal",
  templateUrl: "./lecture-modal.page.html",
  styleUrls: ["./lecture-modal.page.scss"],
})
export class LectureModalPage implements OnInit {
  Lecture: Lecture;
  public IsAttendenceShow: Boolean = false;
  public isAllPresent: boolean;
  rollno: string;

  constructor(
    public gp: GlobalProvider,
    private modalController: ModalController,
    private navParams: NavParams
  ) {}

  ngOnInit() {
    console.table(this.navParams);
  
    this.Lecture = new Lecture();
   this.Lecture.AttendenceList = [];
    this.Lecture = this.navParams.data.Lecture;
    // this.modalTitle = this.navParams.data.paramTitle;
  }

  async closeModal() {
    const onClosedData: string = "Wrapped Up!";
    await this.modalController.dismiss(onClosedData);
  }

  SelectAllStudentAttendence(event) {
    setTimeout(() => {
      this.Lecture.AttendenceList.forEach((item) => {
        item.isPresent = this.isAllPresent;
        item.rollno=this.rollno;
      });
      this.GetPresentStudent();
    });
  }

  SelectStudentAttendence() {
    const allItems = this.Lecture.AttendenceList.length;
    let selected = 0;

    this.Lecture.AttendenceList.map((item) => {
      if (item.isPresent) selected++;
    });

    if (selected > 0 && selected < allItems) {
      this.isAllPresent = false;
    } else if (selected == allItems) {
      // All item selected
      this.isAllPresent = true;
    } else {
      // No item is selected

      this.isAllPresent = false;
    }
    this.GetPresentStudent();
  }
  GetPresentStudent() {
    this.Lecture.lecturedetails.presentstudent = 0;
    if (this.Lecture && this.Lecture.AttendenceList && this.Lecture.AttendenceList.length > 0) {
      this.Lecture.AttendenceList.forEach((item) => {
        this.Lecture.lecturedetails.presentstudent += item.isPresent ? 1 : 0;
      });
    }
  }
}

