export class TeacherforSubjectModel
{
    public classno: number;

    public subno: number

    public compulsory: boolean;

    public y_id: number;

    public classname: string;

    public subjectname: string;

    public staffid: number;

    public staffname: string;

    public message: string;
}

export class RetTeacherforSubjectModel {
    status: string;
    res: TeacherforSubjectModel[];
    errormessage: string;
  }

  