export class User {
    id: number;

    username: string;   //  staff no.
    password: string;

    firstname: string;
    lastname: string;
    middlename: string;

    dob: Date;
    gender: string;
    

    token: string;

    fullname = () => {
        return this.lastname + ' ' + this.firstname + ' ' + this.middlename;
    }

    gendername = () => {
        return this.gender === null ? '' : (
            this.gender === 'M' ? 'Male' : (
                this.gender === 'F' ? 'Female' : 'Others'
        ));
    }
}

export class Student {
    id: number;

    username: string;
    firstName: string;
    lastName: string;
    fatherName: string;
    motherName: string;

    courseName: string;

    rollno: string;
    divisionName: string;

    fullname = () => {
        return this.lastName + ' ' + this.firstName + ' ' + this.fatherName + ' ' + this.motherName;
    }
}
