import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { first } from "rxjs/operators";

import { User, ExamEntry, RetExamData } from "@app/_models";
import { UserService, AuthenticationService, ExamService } from "@services";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ConstantPool } from "@angular/compiler";
import { GlobalProvider } from "@tests/providers/global/global";
//  import { setFlagsFromString } from "v8";

@Component({
  selector: "app-entermarks",
  templateUrl: "./entermarks.component.html",
  styleUrls: ["./entermarks.component.scss"],
})
export class EntermarksComponent implements OnInit {
  examEntry: ExamEntry;
  user: User;
  examForm: FormGroup;

  IsSaving = false;
  showsearch = false;

  typenamelist: string[];
  classnamelist: string[];
  subjectnamelist: string[];
  examassessmentnamelist: string[];
  examnamelist: string[];
  papernamelist: string[];

  studentlist: any[];
  temp_studentlist: any[];
  AssessBreaksList: any[] = [];
  constructor(
    public gp: GlobalProvider,
    private fb: FormBuilder,
    private userService: UserService,
    private examService: ExamService
  ) {
    this.examForm = this.fb.group({
      typename: ["", Validators.required],
      classname: ["", Validators.required],
      subjectname: ["", Validators.required],
      examassessmentname: ["", Validators.required],
      examname: ["", Validators.required],
      papername: ["", Validators.required],
    });

    this.examForm.controls.typename.setValue("Select Exam Type");
  }

  ngOnInit() {
    this.studentlist = [];
    this.examEntry = new ExamEntry();
    this.examEntry.typename = "Select Exam Type";
    this.examEntry.classname = "Select Class";
    this.examEntry.subjectname = "Select Subject";
    this.examEntry.examassessmentname = "Select";
    this.examEntry.examname = "Select";
    this.examEntry.papername = "Select";

    this.examForm.patchValue(this.examEntry);

    // this.typenamelist.push('Select Exam Type');
    // this.classnamelist.push('Select Class');
    // this.subjectnamelist.push('Select Subject');
    // this.examassessmentnamelist.push('Select');
    // this.examnamelist.push('Select');
    // this.papernamelist.push('Select');

    console.log(this.examForm.value);

    this.examService.GetExamTypeDD(this.examEntry).subscribe(
      (retData: RetExamData) => {
        console.log(retData);
        if (
          retData !== null &&
          retData.status !== null &&
          retData.status === "Success"
        ) {
          this.typenamelist = retData.res;
        } else {
          alert("Error fetching Data");
        }
      },
      (err) => {
        console.log("Error - ", err);
      }
    );
  }

  InitialiseDD(level) {
    if (level <= 1) {
      this.classnamelist = [];
      this.examEntry.classname = "Select Class";
      this.classnamelist.push("Select Class");
    }

    if (level <= 2) {
      this.subjectnamelist = [];
      this.examEntry.subjectname = "Select Subject";
      this.subjectnamelist.push("Select Subject");
    }

    if (level <= 3) {
      this.examassessmentnamelist = [];
      this.examEntry.examassessmentname = "Select";
      this.examassessmentnamelist.push("Select");
    }

    if (level <= 4) {
      this.examnamelist = [];
      this.examEntry.examname = "Select";
      this.examnamelist.push("Select");
    }

    if (level <= 5) {
      this.papernamelist = [];
      this.examEntry.papername = "Select";
      this.papernamelist.push("Select");
    }

    this.examForm.patchValue(this.examEntry);
  }

  OnChangeTypeName(event) {
    if (event.target.value.includes("Select")) {
      this.InitialiseDD(1);
      return;
    }
    this.examEntry = this.examForm.value;

    this.examService.GetClassNameDD(this.examEntry).subscribe(
      (retData: RetExamData) => {
        console.log(retData);
        if (
          retData !== null &&
          retData.status !== null &&
          retData.status === "Success"
        ) {
          this.classnamelist = retData.res;
          this.InitialiseDD(2);
        } else {
          alert("Error fetching Data");
        }
      },
      (err) => {
        console.log("Error - ", err);
      }
    );
  }

  OnChangeClassName(event) {
    if (event.target.value.includes("Select")) {
      this.InitialiseDD(2);
      return;
    }
    this.examEntry = this.examForm.value;

    this.examService.GetSubjectNameDD(this.examEntry).subscribe(
      (retData: RetExamData) => {
        console.log(retData);
        if (
          retData !== null &&
          retData.status !== null &&
          retData.status === "Success"
        ) {
          this.subjectnamelist = retData.res;
          this.InitialiseDD(3);
        } else {
          alert("Error fetching Data");
        }
      },
      (err) => {
        console.log("Error - ", err);
      }
    );
  }

  OnChangeSubjectName(event) {
    if (event.target.value.includes("Select")) {
      this.InitialiseDD(3);
      return;
    }
    this.examEntry = this.examForm.value;

    this.examService.GetExamAssessmentDD(this.examEntry).subscribe(
      (retData: RetExamData) => {
        console.log(retData);
        if (
          retData !== null &&
          retData.status !== null &&
          retData.status === "Success"
        ) {
          this.examassessmentnamelist = retData.res;
          this.InitialiseDD(4);
        } else {
          alert("Error fetching Data");
        }
      },
      (err) => {
        console.log("Error - ", err);
      }
    );
  }

  OnChangeExamAssessmentName(event) {
    if (event.target.value.includes("Select")) {
      this.InitialiseDD(4);
      return;
    }
    this.examEntry = this.examForm.value;

    this.examService.GetExamMastDD(this.examEntry).subscribe(
      (retData: RetExamData) => {
        console.log(retData);
        if (
          retData !== null &&
          retData.status !== null &&
          retData.status === "Success"
        ) {
          this.examnamelist = retData.res;
          this.InitialiseDD(5);
        } else {
          alert("Error fetching Data");
        }
      },
      (err) => {
        console.log("Error - ", err);
      }
    );
  }

  OnChangeExamName(event) {
    if (event.target.value.includes("Select")) {
      this.InitialiseDD(5);
      return;
    }
    this.examEntry = this.examForm.value;

    this.examService.GetPaperNameDD(this.examEntry).subscribe(
      (retData: RetExamData) => {
        console.log(retData);
        if (
          retData !== null &&
          retData.status !== null &&
          retData.status === "Success"
        ) {
          this.papernamelist = retData.res;
          this.InitialiseDD(6);
        } else {
          alert("Error fetching Data");
        }
      },
      (err) => {
        console.log("Error - ", err);
      }
    );
  }

  OnChangePaperName(event) {
    if (event.target.value.includes("Select")) {
      this.InitialiseDD(5);
      return;
    }
    this.examEntry = this.examForm.value;

    this.examService.GetSubjwiseStudentsMarksList(this.examEntry).subscribe(
      (retData: any) => {
        debugger;
        console.log(retData);
        if (
          retData !== null &&
          retData.status !== null &&
          retData.status === "Success"
        ) {
          this.studentlist = retData.MSmodelList;
          this.temp_studentlist = retData.MSmodelList;
          this.AssessBreaksList = retData.AssessBreaksList;
          this.showsearch = true;
          this.btnEditMarks = true;
        } else {
          alert("Error fetching Data");
        }
      },
      (err) => {
        console.log("Error - ", err);
      }
    );
  }

  OnChangeMarks(event, i) {
    if (event.target.value !== undefined && event.target.value !== null) {
      console.log(i);
      console.log(this.studentlist[i].amarks);

      this.studentlist[i].amarks = event.target.value;
      this.studentlist[i].cmarks = event.target.value;

      console.log(this.studentlist[i].amarks);
    }
  }

  logForm() {}
  GetConvertedMarks(st) {
    if (
      st.amarks == "undefined" ||
      st.amarks == undefined ||
      (st.amarks == null && st.amarks == "")
    ) {
      st.cmarks = st.amarks;
      return "";
    }
    if (st.amarks == "AB") {
      st.cmarks = st.amarks;
      return "AB";
    }
    if (st.amarks == "CC") {
      st.cmarks = st.amarks;
      return "CC";
    }
    if (st.marksconducted > 0) {
      return (st.cmarks = Math.round(
        (st.amarks * st.marksconverted) / st.marksconducted
      ));
    } else {
      st.cmarks = st.amarks;
      return st.amarks;
    }
  }
  GetActualMarks(st) {
    var keepGoing = true;
    var amarks;
    var mrk = 0;
    if (this.gp.isByMarksABCC) {
      st.AssesBreaksList.forEach((value: any, key) => {
        if (keepGoing) {
          if (
            value.marks == "undefined" ||
            value.marks == undefined ||
            (value.marks == null && value.marks == "")
          ) {
            mrk = Number(mrk) + Number(0);
          } else if (value.marks == "AB" || value.marks == "CC") {
            mrk = Number(mrk) + Number(0);
            amarks = value.marks;
            keepGoing = false;
          } else if (value.marks > value.outofmarks) {
            value.marks = 0;
            alert("Marks should be less than " + value.outofmarks);
            mrk = Number(mrk) + Number(0);
          } else {
            mrk = Number(mrk) + +Number(value.marks);
          }
        }
      });
      //st.amarks = mrk;
      if (amarks == "AB" || amarks == "CC") {
        st.amarks = amarks;
      } else {
        st.amarks = mrk;
      }
    } else {
      st.AssesBreaksList.forEach((value: any, key) => {
        //if (keepGoing {)
        if (
          value.marks == "undefined" ||
          value.marks == undefined ||
          (value.marks == null && value.marks == "")
        ) {
          mrk = Number(mrk) + Number(0);
        } else if (value.marks == "AB" || value.marks == "CC") {
          mrk = Number(mrk) + Number(0);
          amarks = value.marks;
          keepGoing = false;
        } else if (value.marks > value.outofmarks) {
          value.marks = 0;
          alert("Marks should be less than " + value.outofmarks);
          mrk = Number(mrk) + Number(0);
        } else {
          mrk = Number(mrk) + +Number(value.marks);
        }
        // }
      });
      st.amarks = mrk;
      //if (amarks == "AB" || amarks == "CC")
      //{
      //    st.amarks = amarks;
      //}
      //else
      //{
      //    st.amarks = mrk;
      //}
    }
  }
  CancelMarks() {
    //  this.ngOnInit();
    // this.showsearch = false;
    //this.btnEditMarks = false;
    this.editmarks = false;
    //this.studentlist = [];
  }
  GotoFilters() {
    this.ngOnInit();
    this.showsearch = false;
    this.btnEditMarks = false;
    this.studentlist = [];
    [];
  }

  btnEditMarks: boolean = false;
  editmarks: boolean = false;
  EditMarks() {
    this.editmarks = true;
  }
  public PrintAssesBreak: boolean = false;
  PrintAssesBreakupMarks() {
    let fileName =
      this.examEntry.subjectname +
      " (" +
      this.examEntry.examassessmentname +
      " " +
      this.examEntry.typename +
      ").pdf";
    this.examService
      .PrintAssesBreakupMarks(this.examEntry)

      .subscribe((fileData) => {
        const blob: any = new Blob([fileData], { type: "application/pdf" }); //vnd.openxmlformats-officedocument.spreadsheetml.sheet
        debugger;
        let link = document.createElement("a");

        if (link.download !== undefined) {
          let url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
  }

  SaveMarks() {
    this.IsSaving = true;

    setTimeout(() => {
      this.SaveMarksProcess();
    }, 100);
  }

  SaveMarksProcess() {
    let cnt = this.studentlist.length;
    let errorcnt = 0;
    let successcnt = 0;

    this.studentlist.forEach((sl) => {
      sl.savestatus = "Saving...";

      this.examService.SaveMarks(this.examEntry, sl).subscribe(
        (retData: any) => {
          //console.log(retData);
          if (
            retData !== null &&
            retData.status !== null &&
            retData.status === "Success"
          ) {
            // alert('Saved Successfully !!');
            sl.savestatus = "Saved";

            sl.AssesBreaksList = retData.AssesBreaksList;

            // this.IsSaving = false;
            ++successcnt;
          } else {
            // alert('Error fetching Data');
            // this.IsSaving = false;
            sl.savestatus = "Error - " + retData.errormessage;
            ++errorcnt;
          }

          --cnt;
          if (cnt === 0) {
            this.IsSaving = false;
            if (errorcnt === 0) {
              alert(successcnt + " - Saved Successfully !!");
              this.btnEditMarks = false;
              this.editmarks = false;
            } else {
              alert("Errors while saving");
            }
          }

        },
        (err) => {
          console.log("Error - ", err);
          // this.IsSaving = false;
          sl.savestatus = "Error";
        }
      );
    });

  }

}
