export class ExamEntry {
    typename: string;
    classname: string;
    subjectname: string;
    examassessmentname: string;
    examname: string;
    papername: string;
}

export class RetExamData {
    status: string;
    res: any;
    errormessage: string;
}
