import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { first } from "rxjs/operators";

import { User } from "@app/_models";
import {
  UserService,
  AuthenticationService,
  AlertService,
  StudentDataService,
  FileUploadService,
} from "@services";
import { FormGroup, FormBuilder } from "@angular/forms";
import { GlobalProvider } from "../../providers/global/global";
@Component({
  templateUrl: "home.component.html",
  styleUrls: ["home.component.scss"],
})
export class HomeComponent implements OnInit, OnDestroy {
  currentUser: User;
  currentUserSubscription: Subscription;

  staffprofile: any = {};
  assignments: [];
  selassign: any = {};
  assignattach: any;

  profileForm: FormGroup;
  error: string;
  filesize: 0;
  filename: "";
  fileUpload = { status: "", message: "", filePath: "" };
  uploading = false;

  constructor(
    public gp: GlobalProvider,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private alertService: AlertService,
    private studentDataService: StudentDataService,
    private fb: FormBuilder,
    private fileUploadService: FileUploadService
  ) {
    // console.log('In Home');

    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(
      (user) => {
        this.currentUser = user;

        // console.log(user);
        this.gp.GetInstitutedetails();

        this.userService.getUserInfo().subscribe(
          (x: any) => {
            if (x.status === "Success") {
              console.log('Got User Info');
              console.log(x);
              this.gp.staffprofile = x.res;
              console.log(this.gp.staffprofile);
            } else {
              this.alertService.error(x.errormessage);
            }
          },
          (e) => {
            this.alertService.error(e);
            // this.loading = false;
          }
        );
      }
    );
  }

  ngOnInit() {
    // this.loadAllAssignments();

    this.profileForm = this.fb.group({
      name: [""],
      profile: [""],
    });

    this.selassign = null;
    this.filesize = 0;
    this.filename = "";
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.currentUserSubscription.unsubscribe();
  }

  assignselection(a) {
    this.selassign = a;
    this.studentDataService.getAssignmentAttachment(a.assignmentid).subscribe(
      (aa: any) => {
        // console.log(aa);
        this.assignattach = aa.res;
      },
      (err) => console.log(err)
    );
  }

  onSelectedFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.profileForm.get("profile").setValue(file);
      this.filesize = file.size;
      this.filename = file.name;
    }
  }

  onSubmit() {
    this.error = "";

    const formData = new FormData();
    formData.append("assignmentid", this.selassign.assignmentid);
    formData.append("name", this.profileForm.get("name").value);
    formData.append("filename", this.filename);
    formData.append("filesize", this.filesize.toString());

    this.fileUploadService.attemptupload(
      this.selassign.assignmentid,
      this.profileForm.get("name").value,
      this.filename,
      this.filesize.toString()
    );

    formData.append("profile", this.profileForm.get("profile").value);

    this.uploading = true;

    this.fileUploadService.upload(formData).subscribe(
      (res) => {
        this.fileUpload = res;
        this.uploading = false;

        if (res.status === "Success") {
          alert("File uploaded Successfully !!");

          this.assignattach.push(res.res);
        } else {
          this.error = res.errormessage;
        }
      },
      (err) => {
        this.error = err;
        this.uploading = false;
      }
    );
  }

  private loadAllAssignments() {
    this.studentDataService.getAllAssignments().subscribe((a: any) => {
      if (a.status === "Success") {
        this.assignments = a.res;
      }
    });
  }

  dateformatter(params) {
    if (params !== undefined && params !== null) {
      // console.log('In date formatter - ' + params + ', ' + dateformat + ', ' + parseInt(params.value.substr(6)));

      const nowDate = new Date(parseInt(params.substr(6)));

      const strArray = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const d = nowDate.getDate();
      const m = strArray[nowDate.getMonth()];
      const y = nowDate.getFullYear();
      return "" + (d <= 9 ? "0" + d : d) + "-" + m + "-" + y;
    }
    return "";
  }

  standarddateformat(params) {
    if (params !== undefined && params !== null) {
      const nowDate = new Date(params);
      const strArray = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      // const strArray = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
      const d = nowDate.getDate();
      const m = strArray[nowDate.getMonth()];
      const y = nowDate.getFullYear();
      return "" + (d <= 9 ? "0" + d : d) + "-" + m + "-" + y;
    }

    return "";
  }
}
