import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
// import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from "./app.component";

import { AlertComponent } from "./_components";
import { JwtInterceptor, ErrorInterceptor } from "./_helpers";
import { HomeComponent } from "./home";
import { LoginComponent } from "./login";
import { RegisterComponent } from "./register";
import { AuthInterceptor } from "./_helpers/auth.interceptor";
import { ForgotPasswordComponent } from "./forgotpassword/forgotpassword.component";
import { routing } from "./app-routing.module";
import { ExamEntry } from "./_models";
import { EntermarksComponent } from "./entermarks";
import { ViewAssignmentComponent } from "./Syllabus/viewassignment";
import { AssignmentsComponent } from "./Syllabus/assignments";
import { GlobalProvider } from "../providers/global/global";
import { CommonModule } from "@angular/common";
import { SyllabusComponent } from "./Syllabus/syllabus/syllabus.component";
import { LecturesComponent } from "./Syllabus/lectures/lectures.component";
import { LectureModalPage } from "./modals/lecture-modal/lecture-modal.page";
import { TeachforsubComponent } from './teachforsub/teachforsub.component';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    IonicModule.forRoot(),
    routing,
    FormsModule,
    // AppRoutingModule
  ],
  declarations: [
    AppComponent,
    AlertComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    EntermarksComponent,
    ViewAssignmentComponent,
    AssignmentsComponent,
    SyllabusComponent,
    LecturesComponent,
    //  LectureModalPage,
    TeachforsubComponent
  ],
  entryComponents: [],
  providers: [
    StatusBar,
    SplashScreen,
    GlobalProvider,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // provider used to create fake backend
    // fakeBackendProvider
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
