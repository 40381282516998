import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { environment } from "@env";
import { ExamEntry, RetExamData } from "@models";

@Injectable({ providedIn: "root" })
export class ExamService {
  constructor(private http: HttpClient) {}

  public GetExamTypeDD(examentry: ExamEntry) {
    console.log("Exam Type");
    return this.http.post<RetExamData>(
      `${environment.apiUrl}/Result/GetExamTypeDD`,
      { examentry }
    );
  }
  public GetClassNameDD(examentry: ExamEntry) {
    return this.http.post<RetExamData>(
      `${environment.apiUrl}/Result/GetClassNameDD`,
      {
        typename: examentry.typename,
      }
    );
  }
  public GetSubjectNameDD(examentry: ExamEntry) {
    return this.http.post<RetExamData>(
      `${environment.apiUrl}/Result/GetSubjectNameDD`,
      {
        typename: examentry.typename,
        classname: examentry.classname,
      }
    );
  }
  public GetExamAssessmentDD(examentry: ExamEntry) {
    return this.http.post<RetExamData>(
      `${environment.apiUrl}/Result/GetExamAssessmentDD`,
      {
        typename: examentry.typename,
        classname: examentry.classname,
        subjectname: examentry.subjectname,
      }
    );
  }
  public GetExamMastDD(examentry: ExamEntry) {
    return this.http.post<RetExamData>(
      `${environment.apiUrl}/Result/GetExamMastDD`,
      {
        typename: examentry.typename,
        classname: examentry.classname,
        subjectname: examentry.subjectname,
        examassessmentname: examentry.examassessmentname,
      }
    );
  }
  public GetPaperNameDD(examentry: ExamEntry) {
    return this.http.post<RetExamData>(
      `${environment.apiUrl}/Result/GetPaperNameDD`,
      {
        typename: examentry.typename,
        classname: examentry.classname,
        subjectname: examentry.subjectname,
        examassessmentname: examentry.examassessmentname,
        examname: examentry.examname,
      }
    );
  }
  public GetSubjwiseStudentsMarksList(examentry: ExamEntry) {
    return this.http.post<RetExamData>(
      `${environment.apiUrl}Result/GetSubjwiseStudentsMarksList`,
      {
        typename: examentry.typename,
        classname: examentry.classname,
        subjectname: examentry.subjectname,
        examassessmentname: examentry.examassessmentname,
        examname: examentry.examname,
        papername: examentry.papername,
      }
    );
  }

  public SaveMarks(examentry: ExamEntry, studentmarks: any) {
    return this.http.post<RetExamData>(
      `${environment.apiUrl}/Result/SaveSubjectwiseStudMarksData`,
      {
        typename: examentry.typename,
        classname: examentry.classname,
        subjectname: examentry.subjectname,
        examassessmentname: examentry.examassessmentname,
        examname: examentry.examname,
        papername: examentry.papername,
        StuMarksAddEditData: studentmarks,
      }
    );
  }

  public PrintAssesBreakupMarks(examentry: ExamEntry) {
    return this.http.get<any>(
      `${environment.apiUrl}Result/PrintAssesBreakupMarks?typename=` +
        encodeURIComponent(examentry.typename) +
        "&classname=" +
        encodeURIComponent(examentry.classname) +
        "&subjectname=" +
        encodeURIComponent(examentry.subjectname) +
        "&examassessmentname=" +
        encodeURIComponent(examentry.examassessmentname) +
        "&examname=" +
        encodeURIComponent(examentry.examname) +
        "&papername=" +
        encodeURIComponent(examentry.papername),
      { responseType: "blob" as "json" }
    );
  }
}
