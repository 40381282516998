import { Component, OnInit } from "@angular/core";
import {
  User,
  SyllabusModule,
  RetSyllabusData,
  SyllabusSubModule,
} from "@app/_models";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
  UserService,
  MasterDataService,
  SyllabusService,
  AlertService,
} from "@services";
import { GlobalProvider } from "../../../providers/global/global";
import { NavController } from "@ionic/angular";
import { HttpClient } from "@angular/common/http";
import { environment } from "@env";

@Component({
  selector: "app-syllabus",
  templateUrl: "./syllabus.component.html",
  styleUrls: ["./syllabus.component.scss"],
})
export class SyllabusComponent implements OnInit {
  public Module: SyllabusModule;
  public SubModule: SyllabusSubModule;

  public classname: string ='';
  public subname: string = "All";
  public user: User;
  public filterclasslist: string[] = ["All"];
  public filtersubjectlist: string[] = ["All"];
  public filterModuleList: any[];

  public classlist: string[];
  public subjectlist: string[];
  public ModuleNamesDD: string[] = ["Select Module"];
  public ModuleList: [];
  public IsModuleListShow: boolean = true;
  public IsModuleFormShow: boolean = false;
  public IsSubModuleFormShow: boolean = false;

  public ShowModuleList: [];
  public ShowSubModuleList: [];

  constructor(
    public gp: GlobalProvider,
    private userService: UserService,
    private syllabusService: SyllabusService,
    private masterData: MasterDataService,
    public navCtrl: NavController,
    private alertService: AlertService,
    private http: HttpClient 
  ) {}

  shownGroup: any;
  ngOnInit() {
    debugger;
    this.Module = new SyllabusModule();
    this.SubModule = new SyllabusSubModule();
    this.Module.subjectname = "Select Subject";

    this.SubModule.subModulename = "Select Module";

    this.IsModuleFormShow = false;
    this.IsModuleListShow = true;
    this.IsSubModuleFormShow = false;

    this.getModuleList();
  }
  toggleModule = function (m) {
    /* this.filterModuleList.map((mod, i) => {
      this.filterModuleList[i].show = false;
    }); */
    m.show = !m.show;
  };
  isModuleShown = function (m) {
    return m.show;
  };
  CancekModuleForm() {
    this.getModuleList();
    this.Module = new SyllabusModule();

    this.SubModule = new SyllabusSubModule();

    this.IsModuleFormShow = false;
    this.IsModuleListShow = true;
    this.IsSubModuleFormShow = false;
  }
  CancelSubModuleForm() {
    this.getModuleList();
    this.Module = new SyllabusModule();

    this.SubModule = new SyllabusSubModule();

    this.IsModuleFormShow = false;
    this.IsModuleListShow = true;
    this.IsSubModuleFormShow = false;
  }

  getModuleList() {
    debugger;
    this.alertService.showLoader("Please wait....");
    this.syllabusService.GetModuleList().subscribe(
      (retData: RetSyllabusData) => {
        if (
          retData !== null &&
          retData.status !== null &&
          retData.status === "Success"
        ) {
          this.ModuleList = retData.res;
          this.filterModuleList = this.ModuleList;
          debugger;
          if (this.ModuleList.length > 0) {
            this.ModuleList.forEach((obj: any, index) => {
              debugger;
              if (this.filterclasslist.indexOf(obj.Module.classname) === -1) {
                this.filterclasslist.push(obj.Module.classname);
              }
              if (
                this.filtersubjectlist.indexOf(obj.Module.subjectname) === -1
              ) {
                this.filtersubjectlist.push(obj.Module.subjectname);
              }
            });
          }
        } else {
          //alert("Error fetching Data");
          this.alertService.showDangerToast("Error fetching Data");
        }
        this.alertService.hideLoader();
      },
      (err) => {
        console.log("Error - ", err);
      }
    );
  }
  getClassList() {
    this.syllabusService.getClassDD().subscribe(
      (retData: RetSyllabusData) => {
        if (
          retData !== null &&
          retData.status !== null &&
          retData.status === "Success"
        ) {
          this.classlist = retData.res;
        } else {
          //alert("Error fetching Data");
          this.alertService.showDangerToast("Error fetching Data");
        }
      },
      (err) => {
        console.log("Error - ", err);
      }
    );
  }
  OnChangeFilterClassName(event) {
    if (event.target.value == "All" && this.subname == "All") {
      this.filterModuleList = this.ModuleList;
    } else if (event.target.value !== "All" && this.subname !== "All") {
      this.filterModuleList = this.ModuleList.filter(
        (item: any) =>
          item.Module.classname == event.target.value &&
          item.Module.subjectname == this.subname
      );
    } else if (event.target.value === "All" && this.subname !== "All") {
      this.filterModuleList = this.ModuleList.filter(
        (item: any) => item.Module.subjectname == this.subname
      );
    } else if (event.target.value !== "All" && this.subname === "All") {
      this.filterModuleList = this.ModuleList.filter(
        (item: any) => item.Module.classname == event.target.value
      );
    }
    this.filtersubjectlist = [];
    this.filtersubjectlist.push("All");
    if (this.ModuleList.length > 0) {
      if (event.target.value !== "All") {
        this.ModuleList.forEach((obj: any, index) => {
          if (
            this.filtersubjectlist.indexOf(obj.Module.subjectname) === -1 &&
            obj.Module.classname === event.target.value
          ) {
            this.filtersubjectlist.push(obj.Module.subjectname);
          }
        });
      } else {
        this.ModuleList.forEach((obj: any, index) => {
          if (this.filtersubjectlist.indexOf(obj.Module.subjectname) === -1) {
            this.filtersubjectlist.push(obj.Module.subjectname);
          }
        });
      }
    }
  }
  OnChangeFilterSubjectName(event) {
    if (event.target.value == "All" && this.classname == "All") {
      this.filterModuleList = this.ModuleList;
    } else if (event.target.value !== "All" && this.classname !== "All") {
      this.filterModuleList = this.ModuleList.filter(
        (item: any) =>
          item.Module.subjectname == event.target.value &&
          item.Module.classname == this.classname
      );
    } else if (event.target.value === "All" && this.classname !== "All") {
      this.filterModuleList = this.ModuleList.filter(
        (item: any) => item.Module.clasaname == this.classname
      );
    } else if (event.target.value !== "All" && this.classname === "All") {
      this.filterModuleList = this.ModuleList.filter(
        (item: any) => item.Module.subjectname == event.target.value
      );
    }
  }
  getfilterModuleList() {
    if (this.ModuleList.length > 0) {
      if (this.classname != "All") {
        this.filterModuleList = this.ModuleList.filter(
          (item: SyllabusModule) => item.classname == this.classname
        );
      }
      if (this.subname != "All") {
        this.filterModuleList = this.ModuleList.filter(
          (item: SyllabusModule) => item.subjectname == this.subname
        );
      }
    }
  }
  OnChangeClassName(event) {
    var err = "";
    this.subjectlist = ["Select Subject"];
    this.ModuleNamesDD = ["Select Module"];
    this.SubModule.subjectname = "Select Subject";
    this.Module.subjectname = "Select Subject";
    if (event.target.value.includes("Select Class")) {
      err = err + "Select Class";
    }
    if (err != "") {
      //this.alertService.showDangerToast(err);
      return;
    }

    this.getSubjectList(event.target.value);
    if (this.ModuleList.length > 0) {
      if (this.subname != "All") {
        this.filterModuleList = this.ModuleList.filter(
          (item: SyllabusModule) => item.subjectname == this.subname
        );
      }
    }
  }
  OnChangeSubjectName(event, classname) {
    var err = "";
    if (classname == "Select Class") {
      err = err + "Select Class";
    }
    if (event.target.value.includes("Select Subject")) {
      err = err + "Select Subject";
    }
    if (err != "") {
      //this.alertService.showDangerToast(err);
      return;
    }
    this.getModuleDD(classname, event.target.value);
  }

  getSubjectList(ClassName) {
    this.syllabusService.getSubjectDD(ClassName).subscribe(
      (retData: RetSyllabusData) => {
        if (
          retData !== null &&
          retData.status !== null &&
          retData.status === "Success"
        ) {
          this.subjectlist = retData.res;
        } else {
          //alert("Error fetching Data");
          this.alertService.showDangerToast("Error fetching Data");
        }
      },
      (err) => {
        console.log("Error - ", err);
      }
    );
  }
  getModuleDD(ClassName, subjectName) {
    this.syllabusService.getModuleDD(ClassName, subjectName).subscribe(
      (retData: RetSyllabusData) => {
        if (
          retData !== null &&
          retData.status !== null &&
          retData.status === "Success"
        ) {
          this.ModuleNamesDD = retData.res;
        } else {
          //alert("Error fetching Data");
          this.alertService.showDangerToast("Error fetching Data");
        }
      },
      (err) => {
        console.log("Error - ", err);
      }
    );
  }
  AddEditModule(moduleid) {
    this.subjectlist = [];
    if (moduleid == 0) {
      this.Module = new SyllabusModule();

      this.getClassList();
      this.IsModuleFormShow = true;
      this.IsModuleListShow = false;
      this.IsSubModuleFormShow = false;
    } else if (
      moduleid == undefined ||
      moduleid == "" ||
      moduleid == "undefined" ||
      moduleid == null
    ) {
      //alert("Invalid Module");
      this.alertService.showDangerToast("Invalid Module Data");
    } else {
      this.syllabusService.getModuleInfo(moduleid).subscribe(
        (retData: RetSyllabusData) => {
          if (
            retData !== null &&

            retData.status !== null &&
            retData.status === "Success"
          ) {
            this.getClassList();
            this.Module = retData.res;
            this.getSubjectList(this.Module.classname);
            this.IsModuleFormShow = true;
            this.IsModuleListShow = false;
            this.IsSubModuleFormShow = false;
          } else {
            this.Module = new SyllabusModule();

            this.IsModuleFormShow = false;
            this.IsModuleListShow = true;
            this.IsSubModuleFormShow = false;
            this.alertService.showDangerToast("Error fetching Data");
          }
        },
        (err) => {
          this.Module = new SyllabusModule();
          this.IsModuleFormShow = false;
          this.IsModuleListShow = true;
          this.IsSubModuleFormShow = false;
          console.log("Error - ", err);
        }
      );
      
    }
  }
   removeModule(moduleid) {
     debugger;
     if(confirm('Do you want to delete this module?')){
     
      this.syllabusService.DeleteModuleInfo(moduleid).subscribe(

        (retData: any) => {
          if (
           
            retData.status === "Success"
          ){
           
              const deletedItem = this.filterModuleList.forEach(x => moduleid);
              this.filterModuleList.splice(this.filterModuleList.indexOf(deletedItem), 1);
            this.alertService.showSuccessToast("Delete Successfully !!");
          }else{
            this.alertService.showDangerToast(
              "Errors while saving -" + retData.errormessage
            );
          }
        },
        (err) => {
          console.log("Error - ", err);
          this.alertService.hideLoader();
          this.alertService.showDangerToast("Error - " + err);
        }
        );
      //   ()=>{
      //   // this.IsModuleListShow = true;
      // })
      
    }
  }
  removeSubModule(submoduleid) {
    if(confirm('Do you want to delete this submodule?')){
      this.syllabusService.DeleteSubModuleInfo(submoduleid).subscribe(

        (retData: any) => {
          if (
           
            retData.status === "Success"
          ) {
           //var deletedItem = this.filterModuleList.forEach(x => submoduleid);
           // this.filterModuleList.splice(this.filterModuleList.indexOf(deletedItem), 1);
          
          this.filterModuleList.forEach(x => {
              x.SubModules.forEach(y => {
                  if (y.submoduleid === submoduleid) {
                    var deletedItem =  x.SubModules.forEach(x => x.submoduleid == y.submoduleid);
                    x.SubModules.splice(x.SubModules.indexOf(deletedItem), 1);
                  }
              });
             
          });
            this.alertService.showSuccessToast("Delete Successfully !!");
          } else {
            this.alertService.showDangerToast(
              "Errors while saving -" + retData.errormessage
            );
          }
        },
        (err) => {
          console.log("Error - ", err);
          this.alertService.hideLoader();
          this.alertService.showDangerToast("Error - " + err);
        }
      );
      //   ()=>{
      //   // this.IsModuleListShow = true;
      // })
      
    }
  }
  AddEditSubModule(submoduleid) {
    this.subjectlist = [];
    this.ModuleNamesDD = [];
    if (submoduleid == 0) {
      this.SubModule = new SyllabusSubModule();

      this.getClassList();
      this.IsModuleFormShow = false;
      this.IsModuleListShow = false;
      this.IsSubModuleFormShow = true;
    } else if (
      submoduleid == undefined ||
      submoduleid == "" ||
      submoduleid == "undefined" ||
      submoduleid == null
    ) {
      //alert("invalid Sub module");
      this.alertService.showDangerToast("Error fetching Data");
    } else {
      this.syllabusService.getSubModuleInfo(submoduleid).subscribe(
        (retData: RetSyllabusData) => {
          if (
            retData !== null &&
            retData.status !== null &&
            retData.status === "Success"
          ) {
            this.SubModule = retData.res;
            this.getClassList();
            this.getSubjectList(this.SubModule.classname);
            this.getModuleDD(
              this.SubModule.classname,
              this.SubModule.subjectname
            );
            this.IsModuleFormShow = false;
            this.IsModuleListShow = false;
            this.IsSubModuleFormShow = true;
          } else {
            this.SubModule = new SyllabusSubModule();
            this.getClassList();
            this.IsModuleFormShow = false;
            this.IsModuleListShow = true;
            this.IsSubModuleFormShow = false;
            this.alertService.showDangerToast("Error fetching Data");
          }
        },
        (err) => {
          this.SubModule = new SyllabusSubModule();

          this.IsModuleFormShow = false;
          this.IsModuleListShow = true;
          this.IsSubModuleFormShow = false;
          console.log("Error - ", err);
        }
      );
    }
  }

  
  SaveModule() {
    var valdidateError = "";
    if (
      this.Module.classname == null ||
      this.Module.classname == undefined ||
      this.Module.Modulename == ""
    ) {
      valdidateError = valdidateError + "Enter Module Name,";
    }
    if (
      this.Module.classname == null ||
      this.Module.classname == undefined ||
      this.Module.classname == "Select Class"
    ) {
      valdidateError = valdidateError + "Select Class,";
    }

    if (
      this.Module.subjectname == null ||
      this.Module.subjectname == undefined ||
      this.Module.subjectname == "Select Subject"
    ) {
      valdidateError = valdidateError + "Select Subject,";
    }
    if (valdidateError != "") {
      this.alertService.showDangerToast(valdidateError);
      return;
    }

    this.alertService.showLoader("Saving.. Please wait...");
    this.syllabusService.SaveSyllabusModule(this.Module).subscribe(
      (retData: any) => {
        if (
          retData !== null &&
          retData.status !== null &&
          retData.status === "Success"
        ) {
          this.alertService.showSuccessToast("Saved Successfully !!");
          
        } else {
          this.alertService.showDangerToast(
            "Errors while saving -" + retData.errormessage
          );
        }
        this.getModuleList();
        // this.IsModuleFormShow = false;
        //this.IsModuleListShow = true;
        // this.IsSubModuleFormShow = false;
        this.alertService.hideLoader();
        this.getfilterModuleList();
        this.Module = new SyllabusModule();
      },
      (err) => {
        console.log("Error - ", err);
        this.alertService.hideLoader();
        this.alertService.showDangerToast("Error - " + err);
      }
    );
  }
  SaveSubModule() {
    var valdidateError = "";
    if (
      this.SubModule.subModulename == null ||
      this.SubModule.subModulename == undefined ||
      this.SubModule.subModulename == ""
    ) {
      valdidateError = valdidateError + "Enter Sub Module Name,";
    }
    if (
      this.SubModule.classname == null ||
      this.SubModule.classname == undefined ||
      this.SubModule.classname == "Select Class"
    ) {
      valdidateError = valdidateError + "Select Class,";
    }

    if (
      this.SubModule.subjectname == null ||
      this.SubModule.subjectname == undefined ||
      this.SubModule.subjectname == "Select Subject"
    ) {
      valdidateError = valdidateError + "Select Subject,";
    }
    if (
      this.SubModule.Modulename == null ||
      this.SubModule.Modulename == undefined ||
      this.SubModule.Modulename == "Select Module"
    ) {
      valdidateError = valdidateError + "Select Module,";
    }
    if (valdidateError != "") {
      this.alertService.showDangerToast(valdidateError);
      return;
    }
    this.alertService.showLoader("Saving.. Please wait...");

    this.syllabusService.SaveSyllabusSubModule(this.SubModule).subscribe(
      (retData: any) => {
        if (
          retData !== null &&
          retData.status !== null &&
          retData.status === "Success"
        ) {
          this.alertService.showSuccessToast("Saved Successfully !!");
        } else {
          this.alertService.showDangerToast(
            "Errors while saving -" + retData.errormessage
          );
        }
        this.getModuleList();
        // this.IsModuleFormShow = false;
       // this.IsModuleListShow = true;
        // this.IsSubModuleFormShow = false;
        this.alertService.hideLoader();
        this.getfilterModuleList();
        this.SubModule = new SyllabusSubModule();
      },
      (err) => {
        console.log("Error - ", err);
        this.alertService.hideLoader();
        this.alertService.showDangerToast("Error - " + err);
      }
    );
  }
}
