import { Component, OnInit } from '@angular/core';
import {
  User, TeacherforSubjectModel, RetTeacherforSubjectModel
} from "@app/_models";
//  import { FormGroup, FormBuilder, Validators } from "@angular/forms";

import {
  UserService,
  MasterDataService,
  AlertService,
} from "@services";
import { GlobalProvider } from "../../providers/global/global";
import { NavController } from "@ionic/angular";
import { DefaultResponse } from '@app/_models/defaultresponse';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";


@Component({
  selector: 'app-teachforsub',
  templateUrl: './teachforsub.component.html',
  styleUrls: ['./teachforsub.component.scss'],
})
export class TeachforsubComponent implements OnInit {

  public classlist: string[];
  public teacherdata: string[];
  public teachersubjectdata: TeacherforSubjectModel[];
  teasubForm: FormGroup;
  isDataAvailable: boolean = false;

  constructor(
    public gp: GlobalProvider,
    private fb: FormBuilder,
    private userService: UserService,
    private masterData: MasterDataService,
    public navCtrl: NavController,
    private alertService: AlertService
  ) {

    this.teasubForm = this.fb.group({
      teachername: ["", Validators.required],
    });
  }

  ngOnInit() {
    console.log("Teachers");

    this.masterData.getClassDDTeacherForSubject().subscribe(
      (retData: DefaultResponse) => {
        if (retData !== null && retData.status !== null && retData.status === "Success") {
          this.classlist = retData.res; //  this contains list of ClassNames
        }
      }
    );

    this.initializeItems();
  }

  initializeItems(){
    //  this.teacherdata = ["Ram","gopi", "dravid"];

    this.masterData.getStaffTeacherData().subscribe(
      (retData: DefaultResponse) => {
        if (retData !== null && retData.status !== null && retData.status === "Success") {
          this.teacherdata = retData.res; //  this contains list of ClassNames
        }
      }
    );

  }

  getItems(ev: any) {
    // Reset items back to all of the items
    this.initializeItems();

    // set val to the value of the searchbar
    const val = ev.target.value;

    // if the value is an empty string don't filter the items
    if (val && val.trim() !== '') {
        this.isDataAvailable = true;
        this.teacherdata = this.teacherdata.filter((item) => {
            return (item.toLowerCase().indexOf(val.toLowerCase()) > -1);
        })
    } else {
        this.isDataAvailable = false;
    }
  }

  OnChangeClassName(event) {
    if (event.target.value.includes("Select")) {
      // this.InitialiseDD(2);
      return;
    }

    const classname = event.target.value;

    this.masterData.getSelectedClassWiseSubjectData(classname).subscribe(
      (retData: RetTeacherforSubjectModel) => {
        console.log(retData);

        this.teachersubjectdata = retData.res;

        if (
          retData !== null &&
          retData.status !== null &&
          retData.status === "Success"
        ) {
          //this.subjectnamelist = retData.res;
          //this.InitialiseDD(3);
        } else {
          //  alert("Error fetching Data");
        }
      },
      (err) => {
        console.log("Error - ", err);
      }
    );
  }

  saveStaffTeacher(teacherinfo: TeacherforSubjectModel, event) {
    console.log(teacherinfo);
    console.log(event);

    teacherinfo.message = "saving....";

    let saveSTI: TeacherforSubjectModel[] = [];

    saveSTI.push(teacherinfo);
    saveSTI[0].staffname = event.detail.value;

    this.masterData.saveTeacherforSubData(saveSTI).subscribe(
      (retData: RetTeacherforSubjectModel) => {
        console.log(retData);

        if (
          retData !== null &&
          retData.status !== null &&
          retData.status === "Success"
        ) {
          teacherinfo.message = "saved successfully";
          teacherinfo.staffname = event.detail.value;
        } else {
          //  alert("Error fetching Data");
        }
      },
      (err) => {
        console.log("Error - ", err);
      }
    );

  }

}
