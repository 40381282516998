import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@env';

@Injectable({ providedIn: 'root' })
export class StudentDataService {
    constructor(private http: HttpClient) { }

    getAllAssignments() {
        return this.http.get<any[]>(`${environment.apiUrl}StudentInfo/GetAssignmentsForStudent`);
    }

    /// Get Assignment Attachments
    getAssignmentAttachment(id) {
        const data = {assignmentid : id} ;
        return this.http.post<any[]>
            (`${environment.apiUrl}StudentInfo/GetAssignmentAttachmentsOfStudent`, data);
    }

}

