import { Injectable } from "@angular/core";
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType} from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from "@env";
import {
  User,
  SyllabusModule,
  RetSyllabusData,
  SyllabusSubModule,
  Lecture,
} from "@app/_models";

@Injectable({
  providedIn: "root",
})
export class SyllabusService {
  constructor(private http: HttpClient) {}

  GetModuleList() {
    //const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' });

    return this.http.get<RetSyllabusData>(
      `${environment.apiUrl}Syllabus/GetModuleList`
    );
  }
  getClassDD() {
    //const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' });

    return this.http.get<RetSyllabusData>(
      `${environment.apiUrl}Syllabus/getClassDD`
    );
  }
  getSubjectDD(ClassName) {
    //const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' });

    return this.http.get<RetSyllabusData>(
      `${environment.apiUrl}Syllabus/GetSubjectDD`,
      {
        params: {
          classname: ClassName,
        },
      }
    );
  }
  getModuleDD(ClassName, subjectName) {
    //const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' });

    return this.http.get<RetSyllabusData>(
      `${environment.apiUrl}Syllabus/getModuleDD`,
      {
        params: {
          subjectname: subjectName,
          classname: ClassName,
        },
      }
    );
  }
  getSubModuleDD(ClassName, subjectName, moduleName) {
    //const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' });

    return this.http.get<RetSyllabusData>(
      `${environment.apiUrl}Syllabus/getSubModuleDD`,
      {
        params: {
          modulename: moduleName,
          subjectname: subjectName,
          classname: ClassName,
        },
      }
    );
  }


 
  public getModuleInfo(moduleid) {
    return this.http.post<RetSyllabusData>(
      `${environment.apiUrl}Syllabus/GetModuleInfo`,
      {
        moduleid: moduleid,
      }
    );
  }
  public getSubModuleInfo(submoduleid) {
    return this.http.post<RetSyllabusData>(
      `${environment.apiUrl}Syllabus/GetSubModuleInfo`,
      {
        submoduleid: submoduleid,
      }
    );
  }
  public DeleteModuleInfo(moduleid) {
    return this.http.post<RetSyllabusData>(
      `${environment.apiUrl}Syllabus/DeleteModuleInfo`,{moduleid: moduleid});
  }

  public DeleteSubModuleInfo(submoduleid) {
    return this.http.post<RetSyllabusData>(
      `${environment.apiUrl}Syllabus/DeleteSubModuleInfo`, {submoduleid: submoduleid});
     
  }

  public SaveSyllabusModule(Module: SyllabusModule) {
    return this.http.post<RetSyllabusData>(
      `${environment.apiUrl}Syllabus/SaveSyllabusModule`,
      {
        Module: Module,
      }
    );
  }

  public SaveSyllabusSubModule(SubModule: SyllabusSubModule) {
    return this.http.post<RetSyllabusData>(
      `${environment.apiUrl}Syllabus/SaveSyllabusSubModule`,
      {
        SubModule: SubModule,
      }
    );
  }

  GetLectureList(classname, subjectname, modulename, submodulename) {
    //const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' });

    return this.http.post<RetSyllabusData>(
      `${environment.apiUrl}Syllabus/GetLectureList`,
      {
        classname: classname,
        subjectname: subjectname,
        modulename: modulename,
        submodulename: submodulename,
      }
    );
  }
  public getLectureInfo(lectureid) {
    return this.http.post<RetSyllabusData>(
      `${environment.apiUrl}Syllabus/GetLectureInfo`,
      {
        lectureid: lectureid,
      }
    );
  }
  public getLectureAttendenceList(lecturedetails) {
    return this.http.post<RetSyllabusData>(
      `${environment.apiUrl}Syllabus/GetLectureAttendenceList`,
      {
        lecturedetails: lecturedetails,
      }
    );
  }

  public SaveLecture(lecture: Lecture) {
    console.log(lecture);

    let al = [];

    lecture.AttendenceList.forEach(x => {
        al.push({
          rollno: x.rollno,
          isPresent: x.isPresent
        });
    });

    return this.http.post<RetSyllabusData>(
      `${environment.apiUrl}Syllabus/SaveStaffLecture`, { 
        lecturedetails: lecture.lecturedetails,
        AttendenceList: al,  //  lecture.AttendenceList
        stratedon: lecture.stratedon, 
        completedon: lecture.completedon,
      });
  }

  upload(formData) {
    return this.http.post<any>(`${environment.apiUrl}StudentInfo/AddAssignmentAttachmentsOfStudent`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      catchError(this.handleError)
    );
  }

  attemptupload(lectureid) {
    return this.http.post(`${environment.apiUrl}StudentInfo/AttemptAssignmentAttachmentsOfStudent`,
          {lectureid})
        .subscribe(ret => {
            //
        }, err => console.log(err));
  }
  private getEventMessage(event: HttpEvent<any>, formData) {

    switch (event.type) {
      case HttpEventType.UploadProgress:
        return this.fileUploadProgress(event);
		break;
      case HttpEventType.Response:
        return this.apiResponse(event);
		break;
      default:
        return `File "${formData.get('profile').name}" surprising upload event: ${event.type}.`;
    }
  }

  private fileUploadProgress(event) {
    const percentDone = Math.round(100 * event.loaded / event.total);
    return { status: 'progress', message: percentDone };
  }

  private apiResponse(event) {
    return event.body;
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    console.log(error);
    return throwError('Something bad happened. Please try again later.');
  }
}
