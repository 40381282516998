import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { GlobalProvider } from '../../providers/global/global';
import { AlertService, AuthenticationService, UserService, MasterDataService } from '@services';

@Component({
        templateUrl: 'login.component.html',
        styleUrls: ['login.component.scss']
    })
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    LoginRegister = 'Login';
    fpassloading =  false;
    registerForm: FormGroup;
fpassform :FormGroup;
    constructor(
        
        private registerFB: FormBuilder,
        private loginFB: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private userService: UserService,
        private masterdataService: MasterDataService,
        public  gp : GlobalProvider,
    ) {
        console.log('In constructor');

        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.gp.GetInstitutedetails();
        console.log('In init');
        this.loginForm = this.loginFB.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });

        this.registerForm = this.registerFB.group({
            firstname: ['', Validators.required],
            lastname: ['', Validators.required],
            middlename: ['', Validators.required],

            dob: ['', Validators.required],

            username: ['', Validators.required],
            password: ['', [Validators.required, Validators.minLength(6)]]
        });
        this.fpassform = this.registerFB.group({
            firstname: ['', Validators.required],
            lastname: ['', Validators.required],
            middlename: ['', Validators.required],

            dob: ['', Validators.required],

            username: ['', Validators.required],
            password: ['', [Validators.required, Validators.minLength(6)]]
        });
        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        console.log('out of init');
    }

    // convenience getter for easy access to form fields
    get fl() {        console.log('In fl');
        return this.loginForm.controls; }

    // convenience getter for easy access to form fields
    get fr() { return this.registerForm.controls; }


    get ffp() { return this.fpassform.controls; }
    onLoginSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.login(this.loginForm.controls.username.value, this.loginForm.controls.password.value)
            .pipe(first())
            .subscribe(
                data => {
                    console.log('Authenticated');
                    console.log(data);

                    localStorage.setItem('userToken', data.access_token);
                    localStorage.setItem('Username', this.loginForm.controls.username.value);

                    this.gp.GetYearList();
                    this.gp.GetYearSession();

                    // this.router.navigate(['/layout/firsttime-change-password']);
                    this.router.navigate(['/home']);
                },
                error => {
                    // console.log(error);

                    this.alertService.error('Invalid Unique Code or Password');
                    this.loading = false;
                });
    }

    onRegisterSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.registerForm.invalid) {
            console.log(this.registerForm.errors);
            return;
        }

        this.loading = true;
        this.userService.register(this.registerForm.value)
            .pipe(first())
            .subscribe(
                (data: any) => {
                    if (data.status === 'Success') {
                        alert('Registration Successful, \r\nClick OK to proceed to Login Page');
                        this.submitted = false;
                        this.loading = false;

                        this.alertService.success('Registration successful - ' + this.registerForm.controls.username.value, true);

                        this.loginForm.controls.username.setValue('');
                        this.loginForm.controls.password.setValue('');

                        this.registerForm.controls.username.setValue('');
                        this.registerForm.controls.password.setValue('');
                        this.registerForm.controls.firstname.setValue('');
                        this.registerForm.controls.lastname.setValue('');
                        this.registerForm.controls.middlename.setValue('');
                        this.registerForm.controls.dob.setValue('');
                        // this.router.navigate(['/login']);
                        this.LoginRegister = 'Login';

                    } else {
                        this.alertService.error(data.errormessage);
                        this.loading = false;
                    }
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }
    onFpassSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.fpassform.invalid) {
            console.log(this.fpassform.errors);
            return;
        }

        this.fpassloading = true;
        this.userService.forgotpassword(this.fpassform.value)
            .pipe(first())
            .subscribe(
                (data: any) => {
                    if (data.status === 'Success') {
                        alert('Password Reset Successfully, \r\nClick OK to proceed to Login Page');
                        this.alertService.success('Password Reset successful', true);
                        this.router.navigate(['/login']);
                    } else {
                        this.alertService.error(data.errormessage);
                        this.fpassloading = false;
                    }
                },
                error => {
                    this.alertService.error(error);
                    this.fpassloading = false;
                });
    }
    CacelForgotPassForm(){
        this.loginForm.reset();
        this.registerForm.reset();
        this.fpassform.reset();
        this.LoginRegister = 'Login';
    }
    CacelRegisterForm(){
        this.loginForm.reset();
        this.registerForm.reset();
        this.fpassform.reset();
        this.LoginRegister = 'Login';
    }
}


export class DateValidator {
    static ptDate(control: FormControl): { [key: string]: any | null } {
        console.log('Date Validator ');
        console.log(control.value);
    // tslint:disable-next-line: max-line-length
        const ptDatePattern = /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/g;

        if (!control.value.match(ptDatePattern)) {
            return { 'dateIsInvalid': true };
        }
        return null;
    }

    static usDate(control: FormControl): { [key: string]: any } {
        // tslint:disable-next-line: max-line-length
        const usDatePattern = /^02\/(?:[01]\d|2\d)\/(?:19|20)(?:0[048]|[13579][26]|[2468][048])|(?:0[13578]|10|12)\/(?:[0-2]\d|3[01])\/(?:19|20)\d{2}|(?:0[469]|11)\/(?:[0-2]\d|30)\/(?:19|20)\d{2}|02\/(?:[0-1]\d|2[0-8])\/(?:19|20)\d{2}$/;

        if (!control.value.match(usDatePattern)) {
            return { 'dateIsInvalid': true };
        }
        return null;
    }
}
