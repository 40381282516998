import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@env';
import { StudentAssignmentEntry, RetStudentAssignmentData } from '@models';

@Injectable({ providedIn: 'root' })
export class StudentAssignmentService {
    constructor(private http: HttpClient) { }

    public GetClassNameDD() {
        return this.http.get<RetStudentAssignmentData>(`${environment.apiUrl}Assignment/GetClassNameDD`);
    }

    public GetSubjectNameDD(o: StudentAssignmentEntry) {
        return this.http.post<RetStudentAssignmentData>(`${environment.apiUrl}Assignment/GetSubjectNameDD`, {
            classname: o.classname
        });
    }
    public GetAssignmentNameDD(o: StudentAssignmentEntry) {
        return this.http.post<RetStudentAssignmentData>(`${environment.apiUrl}Assignment/GetAssignmentNameDD`, {
            classname: o.classname,
            subjectname: o.subjectname
        });
    }

    public GetStudentAssignmentList(o: StudentAssignmentEntry) {
        return this.http.post<RetStudentAssignmentData>(`${environment.apiUrl}Assignment/GetStudentAssignmentList`, {
            classname: o.classname,
            subjectname: o.subjectname,
            assignmenttitle: o.assignmenttitle,
        });
    }

    public DownloadFile(fileno, filename) {
        return this.http.post(`${environment.apiUrl}Assignment/GetStudentAssignmentFile`, {
            fileno: fileno,
            filename: filename
        }, { responseType: 'blob' as 'blob' });
    }
}

