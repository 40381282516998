export class SyllabusModule {
  moduleid: number = 0;
  Modulename: string;
  moduledesc: string;
  subno: number;
  subjectname: string = "Select Subject";
  classname: string = "Select Class";
  y_id: number;
  active: boolean;
  isdeleted: boolean;
  createdon: Date;
  createdby: number;
  SubModules: SyllabusSubModule[];
}
export class SyllabusSubModule {
  submoduleid: number = 0;
  subModulename: string;
  submoduledesc: string;
  moduleid: number;
  Modulename: "Select Module";
  subjectname: string = "Select Subject";
  classname: string = "Select Class";
  y_id: number;
  active: boolean;
  isdeleted: boolean;
  createdon: Date;
  createdby: number;
}
export class RetSyllabusData {
  status: string;
  res: any;
  errormessage: string;
}
export class Lecture {
  lecturedetails: vSyllabusLectureInfo;
  stratedon: any;
  completedon: any;
  AttendenceList: Attendence[];
  public Lecture() {
    this.lecturedetails = new vSyllabusLectureInfo();
  }
}
export class Attendence {
  grstuno: number;
  name: string;
  seatno: string;
  rollno: string;

  isPresent: boolean;
}

export class vSyllabusLectureInfo {
  lectureid: number = 0;
  lectureheading: string;
  lecturedesc: string;
  presentstudent: number;
  teachingMethod: string;
  teachingaid: string;
  learningoutcome: string;
  startedon: Date;
  completedon: Date;
  createdon_lecture: Date;
  createdby_lecture: number;
  submoduleid: number;
  moduleid: number;
  subModulename: string;
  submoduledesc: string;
  y_id: number;
  active: boolean;
  isdeleted: boolean;
  createdon: Date;
  createdby: number;
  Modulename: string;
  moduledesc: string;
  subno: number;
  classno: number;
  subjectname: string;
  subjectshortname: string;
  suborder: number;
  staffidrelationno: number;
  staffno: string;
  firstname: string;
  middlename: string;
  lastname: string;
  mothersname: string;
  email: string;
  classobtained: string;
  staffcategoryid: number;
  stafftypeid: number;
  staffcategoryname: string;
  departmentname: string;
  stafftypename: string;
  designationname: string;
  rolename: string;
  staffnowithprefix: string;
  fm_date: string;
  to_date: string;
  period: string;
  classname: string;
  longname: string;
}
