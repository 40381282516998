import { Injectable } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { LoadingController } from "@ionic/angular";
import { ToastController } from "@ionic/angular";
@Injectable({ providedIn: "root" })
export class AlertService {
  private subject = new Subject<any>();
  private keepAfterNavigationChange = false;
  isLoading = false;
  constructor(
    private router: Router,
    public loadingController: LoadingController,
    private toastController: ToastController
  ) {
    // clear alert message on route change
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterNavigationChange) {
          // only keep for a single location change
          this.keepAfterNavigationChange = false;
        } else {
          // clear alert
          this.subject.next();
        }
      }
    });
  }

  success(message: string, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({ type: "success", text: message });
  }

  error(message: string, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({ type: "error", text: message });
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
  // This will show then autohide the loader
  showHideAutoLoader() {
    this.loadingController
      .create({
        message: "This Loader Will Auto Hide in 2 Seconds",
        duration: 2000,
      })
      .then((res) => {
        res.present();

        res.onDidDismiss().then((dis) => {
          console.log("Loading dismissed! after 2 Seconds", dis);
        });
      });
  }

  // Show the loader for infinite time
  /* async showLoader(message) {
    await this.loadingController
      .create({
        message: message,
      })
      .then((res) => {
        res.present();
      });
  } */

  // Hide the loader if already created otherwise return error
  /* async hideLoader() {
    await this.loadingController
      .dismiss()
      .then((res) => {
        console.log("Loading dismissed!", res);
      })
      .catch((error) => {
        debugger;
        console.log("error", error);
      });
  } */
  async showLoader(message) {
    this.isLoading = true;
    return await this.loadingController
      .create({
        message: message,
      })
      .then((a) => {
        a.present().then((res) => {
          if (!this.isLoading) {
            console.log("Loading dismissed!", res);
            a.dismiss();
          }
        });
      });
  }

  async hideLoader() {
    if (this.isLoading) {
      this.isLoading = false;
      return await this.loadingController
        .dismiss()
        .then((res) => {
          console.log("Loading dismissed!", res);
        })
        .catch((error) => {
          debugger;
          console.log("error", error);
        });
    }
    return null;
  }

  async showSuccessToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 1000,
      position: "bottom",
      color: "success",
    });
    toast.present();
  }
  async showDangerToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 10000,
      position: "bottom",
      color: "danger",
    });
    toast.present();
  }
}
