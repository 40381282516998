import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from "@env";
import { User } from "@models";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    var us = this.currentUserSubject.value;

    return this.currentUserSubject.value;
  }

  login(username: string, password: string) {
    //  here STAFF_ is prepended
    const data =
      "username=staff_" +
      username +
      "&password=" +
      password +
      "&grant_type=password";

    const reqHeader = new HttpHeaders({ "No-Auth": "True" });
    // return this.http.post(this.rootUrl + 'token', data, { headers: reqHeader });

    // return this.http.post<any>(`${environment.apiUrl}/users/Authenticate`, { username, password }, { headers: reqHeader })
    return this.http
      .post<any>(`${environment.apiUrl}token`, data, { headers: reqHeader })
      .pipe(
        map((user) => {
          console.log("Logged In");
          console.log(user);

          // login successful if there's a jwt token in the response
          if (user && user.access_token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem("currentUser", JSON.stringify(user));
            this.currentUserSubject.next(user);
          }

          return user;
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    this.currentUserSubject.next(null);
  }
}
