export class StudentAssignmentEntry {
    classname: string;
    subjectname: string;
    studentname: string;
    createdbyname: string;
    viewedbyname: string;
    rollno: string;
    division: string;
    assignmenttitle: string;
    assignmentcontent: string;
    filename: string;
    remarks: string;
}

export class RetStudentAssignmentData {
    status: string;
    res: any;
    errormessage: string;
}
