import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { AlertService, AuthenticationService, MasterDataService, UserService } from "@services";
import { User } from "@models";
import { GlobalProvider } from "../providers/global/global";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
 

  title = "edvu-college-staff-web-clientapp-v1";
  period=''


  
  public selectedIndex = 0;
  public appPages = [
    {
      title: "Inbox",
      url: "/folder/Inbox",
      icon: "mail",
    },
    {
      title: "Outbox",
      url: "/folder/Outbox",
      icon: "paper-plane",
    },
    {
      title: "Favorites",
      url: "/folder/Favorites",
      icon: "heart",
    },
    {
      title: "Archived",
      url: "/folder/Archived",
      icon: "archive",
    },
    {
      title: "Trash",
      url: "/folder/Trash",
      icon: "trash",
    },
    {
      title: "Spam",
      url: "/folder/Spam",
      icon: "warning",
    },
  ];
  public labels = ["Family", "Friends", "Notes", "Work", "Travel", "Reminders"];


  //nrSelect=this.gp.yearlist[0].tn.period
 //period =this.gp.yearlist[1].tn.period

  constructor(public gp: GlobalProvider, private router: Router, private masterDataService: MasterDataService) {
    // this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
   
  }
  
  
  ngOnInit() {
    console.log('In app');
    console.log(this.gp.staffprofile);
    if (this.gp === undefined || this.gp === null 
      || this.gp.staffprofile === undefined || this.gp.staffprofile === null 
      || this.gp.staffprofile.Username === undefined || this.gp.staffprofile.Username === null ) {
        this.logout();
      }
      
    this.GetYearList();
   
  }

  
  logout() {
    this.gp.logout();
    this.router.navigate(["/login"]);
  }
  home() {
    this.router.navigate(["/home"]);
  }
  entermarks() {
    this.router.navigate(["/entermarks"]);
  }
  viewassign() {
    this.router.navigate(["/viewassignment"]);
  }
  assignments() {
    this.router.navigate(["/assignments"]);
  }
  gotosyllabus() {
    this.router.navigate(["/modules"]);
  }
  gotolectures() {
    this.router.navigate(["/lectures"]);
  }
  teachersforsubjects() {
    this.router.navigate(["/teacherforsubjects"]);
  }

  isHod() {
    return this.gp !== undefined && this.gp !== null
        && this.gp.staffprofile !== undefined && this.gp.staffprofile !== null
        && this.gp.staffprofile.IsHod !== undefined 
        && this.gp.staffprofile.IsHod !== null
        && this.gp.staffprofile.IsHod === 'Yes' ? true : false;
  }


  ChangeYearSession($event){
   
    console.log(this.period);

    this.masterDataService.ChangeYearSession(this.period)
    .subscribe((aa: any) => {
      if (aa.status === 'Success') {
       this.gp.selectedyear = aa.res 
      }
       console.log(this.gp.selectedyear );
    },
    err => console.log(err));
  }

  GetYearSession() {
    debugger;
    this.masterDataService.GetYearSession()
    .subscribe((a: any) => {  
        if (a.status === 'Success') {
            debugger;         
            this.gp.selectedyear = a.res;
            this.period = a.res.period;
        }
        else{
        
        }
    });
    
  }
  GetYearList() {
   
    this.masterDataService.getYearList()
    .subscribe((a: any) => {
  
        if (a.status === 'Success') {         
            this.gp.yearlist = a.res;            
        }
    });
    setTimeout((period) => {
      debugger;
      if (this.gp.yearlist !== undefined && this.gp.yearlist !== null && this.gp.yearlist.length > 1) {
        this.gp.selectedyear = period.res; 
        
      }
      else {
      this.period='2021-22';
      
      }
    }, 100);
  }
}
