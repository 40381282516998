//import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import {
  UserService,
  MasterDataService,
  SyllabusService,
  AuthenticationService,
  AlertService,
} from "@services";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../src/environments/environment";
import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { Subscription } from "rxjs";
import { User } from "@models";
/*
  Generated class for the GlobalProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class GlobalProvider {
  public institutedetails: institutedetails = new institutedetails();
  public currentUser: User;
  public title = "edvu-college-staff-web-clientapp-v1";
  public isByMarksABCC: boolean = false; /// false for  KMP   and true for ADArsh
  currentUserSubscription: Subscription;
  public staffprofile: any = {};

  public yearlist = [];
  public selectedyear: any = {};

  constructor(
    private http: HttpClient,
    private masterDataService: MasterDataService,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private alertService: AlertService
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(
      (user) => {
        this.currentUser = user;

        // console.log(user);

        this.userService.getUserInfo().subscribe(
          (x: any) => {
            if (x.status === "Success") {
              // console.log('Got User Info');
              // console.log(x);
              this.staffprofile = x.res;
              console.log(this.staffprofile);
            } else {
              this.alertService.error(x.errormessage);
            }
          },
          (e) => {
            this.alertService.error(e);
            // this.loading = false;
          }
        );
      }
    );

    this.initializeApp();
  }
  async initializeApp() {
    var i = this.platform.is("hybrid");
    var A = this.platform;
    //if (this.platform.is("hybrid")) {
    await this.splashScreen.hide();
    await this.statusBar.styleDefault();
    // }
  }
  initializeApps() {
    this.splashScreen.hide();
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }
  GetInstitutedetails() {
    this.masterDataService.GetInstituteDetails().subscribe(
      (data: retData) => {
        console.log("Get Institute");
        console.log(data);
        if (
          data !== null &&
          data.status !== null &&
          data.status === "Success"
        ) {
          this.institutedetails = data.res;
        } else {
          setTimeout(() => {
            this.GetInstitutedetails();
          }, 500);
        }
      },
      (err) => {
        console.log("Error - ", err);
      }
    );
  }
  logout() {
    localStorage.removeItem('Username');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
    localStorage.removeItem('userToken');

    this.authenticationService.logout();
  }
  dateformatter_ddmmyyyy(params) {
    if (params !== undefined && params !== null) {
      // console.log('In date formatter - ' + params + ', ' + dateformat + ', ' + parseInt(params.value.substr(6)));

      const nowDate = new Date(parseInt(params.substr(6)));

      const strArray = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const strMonthNO = [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ];
      const d = nowDate.getDate();
      const m = strMonthNO[nowDate.getMonth()];
      const y = nowDate.getFullYear();

      return "" + (d <= 9 ? "0" + d : d) + "/" + m + "/" + y;
    }
    return "";
  }
  dateformatter(params) {
    if (params !== undefined && params !== null) {
      // console.log('In date formatter - ' + params + ', ' + dateformat + ', ' + parseInt(params.value.substr(6)));

      const nowDate = new Date(parseInt(params.substr(6)));

      const strArray = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const strMonthNO = [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ];
      const d = nowDate.getDate();
      const m = strMonthNO[nowDate.getMonth()];
      const y = nowDate.getFullYear();
      var hours = nowDate.getHours();
      var minutes = nowDate.getMinutes();

      return (
        "" +
        (d <= 9 ? "0" + d : d) +
        "/" +
        m +
        "/" +
        y +
        " " +
        (hours < 10 ? "0" : "") +
        hours +
        ":" +
        (minutes < 10 ? "0" : "") +
        minutes
      );
    }
    return "";
  }

  GetYearList() {
    this.masterDataService.getYearList()
    .subscribe((a: any) => {
  
        if (a.status === 'Success') {         
            this.yearlist = a.res;            
        }
    });
  }

  GetYearSession() {
    this.masterDataService.GetYearSession()
    .subscribe((a: any) => {
        if (a.status === 'Success') {         
            this.selectedyear = a.res;            
        }
        else{
          //  this.authenticationService.logout();
        }
    });
  }
}

export interface retData {
  status: string;
  res: any;
  errormessage: string;
}

export class institutedetails {
  public instituteId: number;
  public instituteName: string;
  public add1: string;
  public add2: string;
  public add3: string;
  public headerlogo: string;
  public printlogo: string;
  public shortname: string;
  public trustid: number;
  public trustname: string;
  public trustaddress: string;
  public trustcontactno: string;
  public universityid: number;
  public active: boolean;
  public isdeleted: boolean;
  public createdon: Date;
  public createdby: number;
}
