import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { User, StudentAssignmentEntry, RetStudentAssignmentData } from '@app/_models';
import { UserService, AuthenticationService, StudentAssignmentService } from '@services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ConstantPool } from '@angular/compiler';

@Component({
  selector: 'app-viewassignment',
  templateUrl: './viewassignment.component.html',
  styleUrls: ['./viewassignment.component.scss'],
})
export class ViewAssignmentComponent implements OnInit {
  stuassEntry: StudentAssignmentEntry;
  user: User;
  selForm: FormGroup;

  IsSaving = false;
  showsearch = false;

  classnamelist: string[];
  subjectnamelist: string[];
  assignmentnamelist: string[];

  studentlist: any[];

  constructor(private fb: FormBuilder,
    private userService: UserService, 
    private studentassignService: StudentAssignmentService) {

      this.selForm = this.fb.group({
        classname: ['', Validators.required],
        subjectname: ['', Validators.required],
        assignmenttitle: ['', Validators.required]
      });

      this.selForm.controls.classname.setValue('Select Class');
  }

  ngOnInit() {
  this.stuassEntry = new StudentAssignmentEntry();
  this.stuassEntry.classname = 'Select Class';
  this.stuassEntry.subjectname = 'All';
  this.stuassEntry.assignmenttitle = 'All';

  this.selForm.patchValue(this.stuassEntry);

  console.log(this.selForm.value);

  this.InitialiseDD(1);

  this.studentassignService.GetClassNameDD()
    .subscribe((retData: RetStudentAssignmentData) => {
      console.log(retData);
      if (retData !== null && retData.status !== null && retData.status === 'Success') {
        this.classnamelist = retData.res;
      }
      else {
        alert('Error fetching Data');
      }
    },
    err => {
      console.log('Error - ', err);
    });
  }

  InitialiseDD(level) {
    if (level <= 1) {
      this.subjectnamelist = [];
      this.stuassEntry.subjectname = 'All';
      this.subjectnamelist.push('All');

      this.assignmentnamelist = [];
      this.stuassEntry.assignmenttitle = 'All';
      this.assignmentnamelist.push('All');
    }

    this.selForm.patchValue(this.stuassEntry);
  }

  OnChangeClassName(event) {
    this.stuassEntry = this.selForm.value;
    this.InitialiseDD(1);

    this.studentassignService.GetSubjectNameDD(this.stuassEntry)
      .subscribe((retData: RetStudentAssignmentData) => {
        console.log(retData);
        if (retData !== null && retData.status !== null && retData.status === 'Success') {
          this.subjectnamelist = retData.res;
          //this.InitialiseDD(3);

          this.OnChangeAssignmentName(null);
        }
        else {
          alert('Error fetching Data');
        }
      },
      err => {
        console.log('Error - ', err);
      }
    );
  }

  OnChangeSubjectName(event) {
    if (event.target.value.includes('All')) {
      this.InitialiseDD(2);
      return;
    }
    this.stuassEntry = this.selForm.value;

    this.studentassignService.GetAssignmentNameDD(this.stuassEntry)
      .subscribe((retData: RetStudentAssignmentData) => {
        console.log(retData);
        if (retData !== null && retData.status !== null && retData.status === 'Success') {
          this.assignmentnamelist = retData.res;
          //this.InitialiseDD(3);
        }
        else {
          alert('Error fetching Data');
        }
      },
      err => {
        console.log('Error - ', err);
      }
    );
  }

  OnChangeAssignmentName(event) {
    this.stuassEntry = this.selForm.value;

    this.studentassignService.GetStudentAssignmentList(this.stuassEntry)
      .subscribe((retData: RetStudentAssignmentData) => {
        console.log(retData);
        if (retData !== null && retData.status !== null && retData.status === 'Success') {
          this.studentlist = retData.res;
        }
        else {
          alert('Error fetching Data');
        }
      },
      err => {
        console.log('Error - ', err);
      }
    );
  }

  DownloadFile(fileno, filename) {
    this.studentassignService.DownloadFile(fileno, filename)
      .subscribe(fileData => {

      const blob: any = new Blob([fileData], { type: 'application' });

      let link = document.createElement("a");

      if (link.download !== undefined) {
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        //link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }, 
    err => {
      alert('Error downloading file ' + filename);
    } );
  }
}

