import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@env';
import { TeacherforSubjectModel } from '@app/_models/teacherforsubject';
import { DefaultResponse } from '@app/_models/defaultresponse';

@Injectable({ providedIn: 'root' })
export class MasterDataService {
    constructor(private http: HttpClient) { }

    getAllCourses() {
        const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' });

        return this.http.get<string[]>(`${environment.apiUrl}masters/GetAllCourses`, {headers: reqHeader});
    }

    GetInstituteDetails() {
        const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' });

        return this.http.get<any>(`${environment.apiUrl}masters/GetInstituteDetails`, {headers: reqHeader});
    }

    // register(user: User) {
    //     return this.http.post(`${environment.apiUrl}/users/register`, user);
    // }

    getClassDDTeacherForSubject() {
        return this.http.get<DefaultResponse>(`${environment.apiUrl}Masters/GetClassDDTeacherForSubject`);
    }

    getStaffTeacherData() {
        return this.http.get<DefaultResponse>(`${environment.apiUrl}Masters/GetStaffTeacherData`);
    }

    getSelectedClassWiseSubjectData(classname: string) {
        var data = {classname: classname};
        return this.http.post<any>(`${environment.apiUrl}Masters/GetSelectedClassWiseSubjectData`, data);
    }

    saveTeacherforSubData(data: TeacherforSubjectModel[]) {
        return this.http.post<any>(`${environment.apiUrl}Masters/SaveTeacherforSubData`, data);
    }

    ChangeYearSession(period){
        const data = {period : period} ;
        return this.http.post<any>(`${environment.apiUrl}masters/ChangeYearSession`, data);
    }

    GetYearSession(){
        return this.http.get(`${environment.apiUrl}masters/GetYearSessionStaff`);
    }

    getYearList(){
        return this.http.get(`${environment.apiUrl}masters/GetYearsList`);
    }
}
