import { Component, OnInit } from "@angular/core";
import {
  User,
  SyllabusModule,
  RetSyllabusData,
  SyllabusSubModule,
  vSyllabusLectureInfo,
  Lecture,
  Attendence,
} from "@app/_models";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
  UserService,
  MasterDataService,
  SyllabusService,
  AlertService,
} from "@services";
import { GlobalProvider } from "../../../providers/global/global";
import { NavController } from "@ionic/angular";
import { ModalController } from "@ionic/angular";
import { LectureModalPage } from "../../modals/lecture-modal/lecture-modal.page";
@Component({
  selector: "app-assignments",
  templateUrl: "./assignments.component.html",
  styleUrls: ["./assignments.component.scss"],
})
export class AssignmentsComponent implements OnInit {
  constructor(
    public gp: GlobalProvider,
    private userService: UserService,
    private syllabusService: SyllabusService,
    private masterData: MasterDataService,
    public navCtrl: NavController,
    public alertService: AlertService,
    public modalController: ModalController
  ) {}
  public lecture: Lecture;
  public IsLectureFormShow: Boolean = false;
  public IsAttendenceShow: Boolean = false;
  public isAllPresent: boolean = false;
  public LectureList: [];
  public filterclasslist: string[] = ["All"];
  public filtersubjectlist: string[] = ["All"];
  public filtermodulelist: string[] = ["All"];
  public filtersubmodulelist: string[] = ["All"];
  public filterLectureList: any[];

  public classname: string = "All";
  public subname: string = "All";
  public modname: string = "All";
  public submodname: string = "All";
  user: User;
  dataReturned: any;
  classlist: string[];
  subjectlist: string[];
  ModuleNamesDD: string[];
  SubModuleNamesDD: string[];
  AttendenceList: Attendence[];

  fileUpload = { status: "", message: "", filePath: "" };
  fileList: FileList;
  ngOnInit() {
    this.lecture = new Lecture();
    this.lecture.lecturedetails = new vSyllabusLectureInfo();
    this.gp.initializeApp();
    this.getLectureList();
    console.log(this.gp.currentUser);
  }
  async openModal(lect) {
    const modal = await this.modalController.create({
      component: LectureModalPage,
      componentProps: {
        Lecture: lect,
      },
    });

    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        this.dataReturned = dataReturned.data;
        //alert('Modal Sent Data :'+ dataReturned);
      }
    });

    return await modal.present();
  }
  getLectureList() {
    this.alertService.showLoader("Please wait....");
    this.syllabusService
      .GetLectureList(
        this.classname,
        this.subname,
        this.modname,
        this.submodname
      )
      .subscribe(
        (retData: RetSyllabusData) => {
          if (
            retData !== null &&
            retData.status !== null &&
            retData.status === "Success"
          ) {
            this.LectureList = retData.res;
            this.filterLectureList = this.LectureList;
            if (this.LectureList.length > 0) {
              this.LectureList.forEach((obj: any, index) => {
                if (
                  this.filterclasslist.indexOf(obj.lecturedetails.classname) ===
                  -1
                ) {
                  this.filterclasslist.push(obj.lecturedetails.classname);
                }
                if (
                  this.filtersubjectlist.indexOf(
                    obj.lecturedetails.subjectname
                  ) === -1
                ) {
                  this.filtersubjectlist.push(obj.lecturedetails.subjectname);
                }
                if (
                  this.filtermodulelist.indexOf(
                    obj.lecturedetails.Modulename
                  ) === -1
                ) {
                  this.filtermodulelist.push(obj.lecturedetails.Modulename);
                }
                if (
                  this.filtersubmodulelist.indexOf(
                    obj.lecturedetails.subModulename
                  ) === -1
                ) {
                  this.filtersubmodulelist.push(
                    obj.lecturedetails.subModulename
                  );
                }
              });
            }
          } else {
            //alert("Error fetching Data");
            this.alertService.showDangerToast("Error fetching Data");
          }
          this.alertService.hideLoader();
        },
        (err) => {
          console.log("Error - ", err);
          this.alertService.showDangerToast("Error - " + err);
          this.alertService.hideLoader();
        }
      );
  }
  getfilterLectureList() {
    if (this.LectureList.length > 0) {
      this.filterLectureList = this.LectureList;
      if (this.classname != "All") {
        this.filterLectureList = this.filterLectureList.filter(
          (item: Lecture) => item.lecturedetails.classname == this.classname
        );
      }
      if (this.subname != "All") {
        this.filterLectureList = this.filterLectureList.filter(
          (item: Lecture) => item.lecturedetails.subjectname == this.subname
        );
      }
      if (this.modname != "All") {
        this.filterLectureList = this.filterLectureList.filter(
          (item: Lecture) => item.lecturedetails.Modulename == this.modname
        );
      }
      if (this.submodname != "All") {
        this.filterLectureList = this.filterLectureList.filter(
          (item: Lecture) =>
            item.lecturedetails.subModulename == this.submodname
        );
      }
    }
  }
  getClassList() {
    this.syllabusService.getClassDD().subscribe(
      (retData: RetSyllabusData) => {
        if (
          retData !== null &&
          retData.status !== null &&
          retData.status === "Success"
        ) {
          this.classlist = retData.res;
        } else {
          //alert("Error fetching Data");
          this.alertService.showDangerToast("Error fetching Data");
        }
      },
      (err) => {
        console.log("Error - ", err);
      }
    );
  }

  getSubjectList(ClassName) {
    this.syllabusService.getSubjectDD(ClassName).subscribe(
      (retData: RetSyllabusData) => {
        if (
          retData !== null &&
          retData.status !== null &&
          retData.status === "Success"
        ) {
          this.subjectlist = retData.res;
        } else {
          //alert("Error fetching Data");
          this.alertService.showDangerToast("Error fetching Data");
        }
      },
      (err) => {
        console.log("Error - ", err);
      }
    );
  }
  getModuleDD(ClassName, subjectName) {
    this.syllabusService.getModuleDD(ClassName, subjectName).subscribe(
      (retData: RetSyllabusData) => {
        if (
          retData !== null &&
          retData.status !== null &&
          retData.status === "Success"
        ) {
          this.ModuleNamesDD = retData.res;
          this.getLectureAttendenceList();
        } else {
          //alert("Error fetching Data");
          this.alertService.showDangerToast("Error fetching Data");
        }
      },
      (err) => {
        console.log("Error - ", err);
      }
    );
  }
  getSubModuleDD(ClassName, subjectName, modulename) {
    this.syllabusService
      .getSubModuleDD(ClassName, subjectName, modulename)
      .subscribe(
        (retData: RetSyllabusData) => {
          if (
            retData !== null &&
            retData.status !== null &&
            retData.status === "Success"
          ) {
            this.SubModuleNamesDD = retData.res;
          } else {
            //alert("Error fetching Data");
            this.alertService.showDangerToast("Error fetching Data");
          }
        },
        (err) => {
          console.log("Error - ", err);
        }
      );
  }
  OnChangeClassName(event) {
    var err = "";
    this.subjectlist = ["Select Subject"];
    this.ModuleNamesDD = ["Select Module"];
    this.SubModuleNamesDD = ["Select Sub Module"];
    this.lecture.lecturedetails.subjectname = "Select Subject";
    this.lecture.lecturedetails.Modulename = "Select Module";
    this.lecture.lecturedetails.subModulename = "Select Sub Module";
    if (event.target.value.includes("Select Class")) {
      err = err + "Select Class";
    }
    if (err != "") {
      //this.alertService.showDangerToast(err);
      return;
    }

    this.getSubjectList(event.target.value);
  }
  OnChangeSubjectName(event, classname) {
    this.ModuleNamesDD = ["Select Module"];
    this.SubModuleNamesDD = ["Select Sub Module"];
    this.lecture.lecturedetails.Modulename = "Select Module";
    this.lecture.lecturedetails.subModulename = "Select Sub Module";
    var err = "";
    if (classname == "Select Class") {
      err = err + "Select Class";
    }
    if (event.target.value.includes("Select Subject")) {
      err = err + "Select Subject";
    }
    if (err != "") {
      //this.alertService.showDangerToast(err);
      return;
    }
    this.getModuleDD(classname, event.target.value);
  }

  OnChangeModuleName(event, classname, subjectname) {
    var err = "";
    this.SubModuleNamesDD = ["Select Sub Module"];
    this.lecture.lecturedetails.subModulename = "Select Sub Module";

    if (classname == "Select Class") {
      err = err + "Select Class";
    }
    if (subjectname == "Select Subject") {
      err = err + "Select Subject";
    }
    if (event.target.value.includes("Select Module")) {
      err = err + "Select Module";
    }
    if (err != "") {
      this.alertService.showDangerToast(err);
      return;
    }
    this.getSubModuleDD(classname, subjectname, event.target.value);
  }

  getLectureAttendenceList() {
    if (this.lecture.lecturedetails.classname == "Select Class") {
      this.lecture.AttendenceList = [];
      return;
    }

    if (this.lecture.lecturedetails.subjectname == "Select Subject") {
      this.lecture.AttendenceList = [];
      return;
    }
    this.syllabusService
      .getLectureAttendenceList(this.lecture.lecturedetails)
      .subscribe(
        (retData: RetSyllabusData) => {
          if (
            retData !== null &&
            retData.status !== null &&
            retData.status === "Success"
          ) {
            this.lecture.AttendenceList = retData.res;
            this.IsAttendenceShow = false;
            this.isAllPresent = false;
            var presentcount = 0;
            if (this.lecture.AttendenceList.length > 0) {
              this.IsAttendenceShow = true;
              this.lecture.AttendenceList.forEach((obj: any, index) => {
                if (obj.isPresent) {
                  presentcount++;
                }
              });
              this.lecture.lecturedetails.presentstudent = presentcount;
              if (presentcount == this.lecture.AttendenceList.length) {
                this.isAllPresent = true;
              }
            }
          } else {
            //alert("Error fetching Data");
            this.alertService.showDangerToast("Error fetching Data");
          }
        },
        (err) => {
          console.log("Error - ", err);
        }
      );
    this.GetPresentStudent();
  }
  AddEditLecture(lectureid) {
    if (lectureid == 0) {
      this.lecture = new Lecture();
      this.lecture.lecturedetails = new vSyllabusLectureInfo();
      this.getClassList();
      this.IsLectureFormShow = true;
    } else if (
      lectureid == undefined ||
      lectureid == "" ||
      lectureid == "undefined" ||
      lectureid == null
    ) {
      //alert("Invalid Module");
      this.alertService.showDangerToast("Invalid Lecture Data");
    } else {
      this.syllabusService.getLectureInfo(lectureid).subscribe(
        (retData: RetSyllabusData) => {
          if (
            retData !== null &&
            retData.status !== null &&
            retData.status === "Success"
          ) {
            this.lecture = new Lecture();
            this.lecture = retData.res;
            console.log("sssss");
            console.log(this.lecture);
            if (this.lecture.AttendenceList.length > 0) {
              this.IsAttendenceShow = true;
            }
            /*   this.getClassList();
            this.getSubjectList(this.lecture.lecturedetails.classname);
            this.getModuleDD(
              this.lecture.lecturedetails.classname,
              this.lecture.lecturedetails.subjectname
            );
            this.getSubModuleDD(
              this.lecture.lecturedetails.classname,
              this.lecture.lecturedetails.subjectname,
              this.lecture.lecturedetails.Modulename
            ); */
            this.IsLectureFormShow = true;
          } else {
            this.lecture = new Lecture();
            this.lecture.lecturedetails = new vSyllabusLectureInfo();
            this.IsLectureFormShow = false;
            this.alertService.showDangerToast("Error fetching Data");
          }
        },
        (err) => {
          this.IsLectureFormShow = false;
          console.log("Error - ", err);
        }
      );
    }
  }
  CancelLectureForm() {
    this.IsLectureFormShow = false;
    this.lecture = new Lecture();
    this.lecture.lecturedetails = new vSyllabusLectureInfo();
    this.classlist = [];
    this.subjectlist = [];
    this.ModuleNamesDD = [];
    this.SubModuleNamesDD = [];
    //this.GetPresentStudent();
  }

  SelectAllStudentAttendence(event) {
    setTimeout(() => {
      this.lecture.AttendenceList.forEach((item) => {
        item.isPresent = this.isAllPresent;
      });
      this.GetPresentStudent();
    });
  }

  SelectStudentAttendence() {
    const allItems = this.lecture.AttendenceList.length;
    let selected = 0;

    this.lecture.AttendenceList.map((item) => {
      if (item.isPresent) selected++;
    });

    if (selected > 0 && selected < allItems) {
      this.isAllPresent = false;
    } else if (selected == allItems) {
      // All item selected
      this.isAllPresent = true;
    } else {
      // No item is selected

      this.isAllPresent = false;
    }
    this.GetPresentStudent();
  }
  GetPresentStudent() {
    this.lecture.lecturedetails.presentstudent = 0;
    if (this.lecture.AttendenceList.length > 0) {
      this.lecture.AttendenceList.forEach((item) => {
        this.lecture.lecturedetails.presentstudent += item.isPresent ? 1 : 0;
      });
    }
  }
  dateformatter(params) {
    if (params !== undefined && params !== null) {
      // console.log('In date formatter - ' + params + ', ' + dateformat + ', ' + parseInt(params.value.substr(6)));

      const nowDate = new Date(parseInt(params.substr(6)));

      const strArray = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const d = nowDate.getDate();
      const m = strArray[nowDate.getMonth()];
      const y = nowDate.getFullYear();
      return "" + (d <= 9 ? "0" + d : d) + "-" + m + "-" + y;
    }
    return "";
  }
  SaveLecture() {
    var valdidateError = this.validateLectureForm();

    if (valdidateError != "") {
      this.alertService.showDangerToast(valdidateError);
      return;
    }

    if (
      this.lecture.completedon != null &&
      this.lecture.completedon != "" &&
      this.lecture.completedon != undefined
    ) {
      this.lecture.lecturedetails.completedon = this.lecture.completedon;
    }
    if (
      this.lecture.stratedon != null &&
      this.lecture.stratedon != "" &&
      this.lecture.stratedon != undefined
    ) {
      this.lecture.lecturedetails.startedon = this.lecture.stratedon;
    }
    this.syllabusService.SaveLecture(this.lecture).subscribe(
      (retData: any) => {
        if (
          retData !== null &&
          retData.status !== null &&
          retData.status === "Success"
        ) {
          // this.FileUpload(retData.lecture.lectureid);
          this.getLectureList();
          this.alertService.showSuccessToast("Saved Successfully !!");
          this.IsLectureFormShow = false;
          this.IsAttendenceShow = false;
          this.getfilterLectureList();
        } else {
          this.alertService.showDangerToast(
            "Errors while saving -" + retData.errormessage
          );
        }
      },
      (err) => {
        console.log("Error - ", err);
      }
    );
  }
  validateLectureForm() {
    var valdidateError = "";
    if (
      this.lecture.lecturedetails.subModulename == null ||
      this.lecture.lecturedetails.subModulename == undefined ||
      this.lecture.lecturedetails.subModulename == ""
    ) {
      valdidateError = valdidateError + "Enter Sub Module Name,";
    }
    if (
      this.lecture.lecturedetails.Modulename == null ||
      this.lecture.lecturedetails.Modulename == undefined ||
      this.lecture.lecturedetails.Modulename == ""
    ) {
      valdidateError = valdidateError + "Enter Module Name,";
    }
    if (
      this.lecture.lecturedetails.classname == null ||
      this.lecture.lecturedetails.classname == undefined ||
      this.lecture.lecturedetails.classname == "Select Class"
    ) {
      valdidateError = valdidateError + "Select Class,";
    }

    if (
      this.lecture.lecturedetails.subjectname == null ||
      this.lecture.lecturedetails.subjectname == undefined ||
      this.lecture.lecturedetails.subjectname == "Select Subject"
    ) {
      valdidateError = valdidateError + "Select Subject,";
    }
    return valdidateError;
  }
  loadFile(event) {
    this.fileList = event.target.files;
  }
  FileUpload(lectureid) {
    const formData = new FormData();
    formData.append("lectureid", lectureid);

    this.syllabusService.attemptupload(lectureid);

    if (this.fileList.length > 0) {
      let file: File = this.fileList[0];
      formData.append("uploadFile", file, file.name);
    }

    this.syllabusService.upload(formData).subscribe(
      (res) => {
        this.fileUpload = res;
        if (res.status === "Success") {
          this.alertService.showSuccessToast("File uploaded Successfully !!");
        } else {
          this.alertService.showDangerToast(
            "Errors while saving -" + res.errormessage
          );
        }
      },
      (err) => {
        this.alertService.showDangerToast("Errors while saving -" + err);
      }
    );
  }
  AddEditAssignment(id) {

  }
}
